import React from 'react'
import clientConfig from '../../client-config'
import {graphql} from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'


export const query = graphql`
  query ContentPageTemplateQuery($id: String!) {
    contentPage: sanityContentPage(id: {eq: $id}) {
      id
      title
      _rawContent
    }
  }
`

const ContentPage = props => {
  const {data, errors} = props
  const contentPage = data && data.contentPage
  const content = contentPage._rawContent
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {contentPage && <SEO title={contentPage.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Container>
        
        <h1>{contentPage.title}</h1>
        <BlockContent 
          blocks={content} 
          projectId={clientConfig.sanity.projectId}
          dataset={clientConfig.sanity.dataset}
        />
      </Container>
    </Layout>
  )
}

export default ContentPage
